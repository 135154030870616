

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import { Component, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { mixins } from 'vue-class-component';
import { getComponent, groupBy } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import DataLoading from '@/lib/mixins/dataLoading';
import { currentDate } from '@/utils/dates';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import { ALL_TASQS_LIST_ITEM, TASQ_WAITING_ON_LIST } from '@/lib/constants';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import scheduleModule from '@/store/modules/scheduleModule';
import workflowModule from '@/store/modules/workflowModule';
import workspaceModule from '@/store/modules/workspaceModule';
import assetsModule from '@/store/modules/assetsModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TasqItemKanban: () => getComponent('tasqs/TasqItemKanban'),
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    ScheduleModal: () => getComponent('common/ScheduleModal'),
    draggable,
    Multiselect,
  },
})
export default class Kanban extends mixins() {
    // Schedule section
    popupBackground = false

    addToSchedulePopupInnerShowing = false

    @Debounce(600)
    scrollList(item, type) {
      // console.log(item);
      this.draggableData = this.draggableData.map((data) => {
        if (data && data.email === item.email && item.new && type === 'new') {
          if (data.cardLimitNew < item.new.length) {
            // eslint-disable-next-line no-param-reassign
            data.cardLimitNew += 10;
          }
        } else if (data && data.email === item.email && item.inProgress && type === 'inProgress') {
          if (data.cardLimitInProgress < item.inProgress.length) {
            // eslint-disable-next-line no-param-reassign
            data.cardLimitInProgress += 10;
          }
        } else if (data && data.email === item.email && item.waitingOn && type === 'waitingOn') {
          if (data.cardLimitWaitingOn < item.waitingOn.length) {
            // eslint-disable-next-line no-param-reassign
            data.cardLimitWaitingOn += 10;
          }
        }
        return data;
      });
      // console.log(item);
      // console.log(item3);
      // item.cardLimitNew += 3;
    }

    addToScheduleIsLoading = false

    addJobChecklistPopup = false

    addToScheduleScheduleSelected = null

    addJobGroupSelected = ''

    checklistTemporaryText = ''

    waitingOnAction: any = {};

    refreshResponseDataKey = 0

    kanbanSelectedUsername = accountModule.user.email;

    addJobShowChecklistPopupValues: any[] = []

    addJobResponseData = {}

    addJobIsLoading = false

    showWaitingOnModal = false;

    showWaitingOnComment = false;

     actionErrors: any = [];

     // End schedule section

  dataLoading = false

  selectedUsernames: any = []

  typesOptions = [] as any;

   isActionNotPerform = false;

  showFilterCycleTimeMenu = false;

  showRouteMenuCloseBtn = false;

  showAreasMenuCloseBtn = false;

  showFilterRouteMenu = false;

  showFilterAreasMenu = false;

  cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70', '80', '90'];

  selectedAreas: any = [];

  defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];

    maxNumCycleMax = 7;

   maxNumDefermentMax = 7;

  showFilterDefermentMenu = false;

  showDefermentMenuCloseBtn = false

  showFilterJobTypeMenu = false;

  showFilterUserMenu = false;

  showTeamMenuCloseBtn = false;

  showFilterTeamMenu =false;

  hideWellDetail = false;

  activeMoveTasqID: any = null;

  showCycleTimeMenuCloseBtn = false;

  showJobTypeMenuCloseBtn = false

  showUserMenuCloseBtn = false;

  hideSignals = false;

  cycleTimeDays: any = null;

  filterType = '';

  showModal = false;

  showTasqCountTooltip = false;

  showScheduleModal = false

   isOnlineApp = navigator.onLine;

  defermentDays: any = null;

  showFilterMenu = false;

  selectedRoutes: any = [];

  selectedTeams: any = []

  cycleTimeGreaterThanFilter: any = null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  draggableData: {
    username: string,
    showUserDetails: boolean,
    color: string,
    email: string,
    cardLimitNew: number,
    cardLimitInProgress: number,
    cardLimitWaitingOn: number,
    initials: string,
    tasqs: TasqJob[],
    new: TasqJob[],
    inProgress: TasqJob[],
    waitingOn: TasqJob[],
    scheduled: TasqJob[],
    resolved: TasqJob[],
  }[] = [];

  get currentDate() {
    return currentDate();
  }

  nameWithDays(name) {
    return `${name} ${name === '1' ? 'Day' : 'Days'}`;
  }

  nameWithUnits(name) {
    return `${name}`;
  }

  setKanbanSelectedUsername(email) {
    if (this.kanbanSelectedUsername !== email) {
      this.draggableData = this.draggableData.map((data) => {
        if (data && data.email === email) {
          // eslint-disable-next-line no-param-reassign
          data.cardLimitNew = 10;

          // eslint-disable-next-line no-param-reassign
          data.cardLimitInProgress = 10;

          // eslint-disable-next-line no-param-reassign
          data.cardLimitInProgress = 10;
        }
        return data;
      });
      this.kanbanSelectedUsername = email;
    } else {
      this.kanbanSelectedUsername = '';
    }
  }

  get selectedFilterJobType() {
    return tasqsListModule.jobTypeFilter;
  }

  get selectedFilterCycleTimeGreaterThan() {
    return tasqsListModule.cycleTimeGreaterThanFilter;
  }

  get selectedFilterCycleTimeLessThan() {
    return tasqsListModule.cycleTimeLessThanFilter;
  }

  get selectedFilterDefermentGreaterThan() {
    return tasqsListModule.unitsGreaterThanFilter;
  }

  get selectedFilterDefermentLessThan() {
    return tasqsListModule.unitsLessThanFilter;
  }

  get selectedFilterUsernames() {
    return tasqsListModule.usernamesList;
  }

  get selectedFilterArea() {
    return tasqsListModule.activeFilterArea;
  }

  get selectedFilterRoute() {
    return tasqsListModule.activeFilterRoute;
  }

  get filteredTasqs() {
    let results = this.tasqs;

    if (results === []) {
      return [];
    }



    if (this.selectedFilterJobType && this.selectedFilterJobType.length) {
      results = results.filter((t) => this.selectedFilterJobType.includes(t.predictionType));
    }

    if (this.selectedFilterUsernames && this.selectedFilterUsernames.length) {
      results = results.filter((t) => this.selectedFilterUsernames.includes(t.username));
    }

    if (this.selectedFilterArea && this.selectedFilterArea.length) {
      // @ts-ignore
      results = results.filter((t) => t.area && this.selectedFilterArea.includes(t.area));
    }

    if (this.selectedFilterRoute && this.selectedFilterRoute.length) {
      results = results.filter((t) => this.selectedFilterRoute.includes(t.route));
    }

    if (this.selectedFilterDefermentGreaterThan) {
      results = results.filter((t) => t.defermentValue && t.defermentValue > this.selectedFilterDefermentGreaterThan);
    }

    if (this.selectedFilterDefermentLessThan) {
      results = results.filter((t) => t.defermentValue && t.defermentValue < this.selectedFilterDefermentLessThan);
    }

    if (this.selectedFilterCycleTimeGreaterThan) {
      results = results.filter((t) => t.deferringDays && t.deferringDays > this.selectedFilterCycleTimeGreaterThan);
    }

    if (this.selectedFilterCycleTimeLessThan) {
      results = results.filter((t) => t.deferringDays && t.deferringDays < this.selectedFilterCycleTimeLessThan);
    }


    return results;

  }

  get tasqs(): TasqJob[] {
    console.log(this.showModal);
    const tasqList: any[] = [];
    const completedList: any[] = [];
    for (var x = 0; x < tasqsListModule.tasqList.length; x++) {
      var tasq_was_schedule = false;
      // @ts-ignore
      for (var y = 0; y < workspaceModule.totalItems.length; y++) {
        // @ts-ignore
        if (workspaceModule.totalItems[y].id == tasqsListModule.tasqList[x].id) {
          tasq_was_schedule = true;
          break;
        }
      }
      if (!tasq_was_schedule) {
        tasqList.push(tasqsListModule.tasqList[x]);
      }
    }
    for (var x = 0; x < tasqsListModule.completedTasqList.length; x++) {
      var tasq_was_schedule = false;
      // @ts-ignore
      for (var y = 0; y < workspaceModule.totalItems.length; y++) {
        // @ts-ignore
        if (workspaceModule.totalItems[y].id == tasqsListModule.completedTasqList[x].id) {
          tasq_was_schedule = true;
          break;
        }
      }
      if (!tasq_was_schedule) {
        completedList.push(tasqsListModule.completedTasqList[x]);
      }
    }
    let workspaceData = workspaceModule.totalItems;

    // console.log(tasqsListModule.usernamesList)

    if (tasqsListModule.usernamesList.length) {
      workspaceData = workspaceData.filter((data) => {
        if (tasqsListModule.usernamesList.includes(data.username)) {
          return true;
        }
        return false;
      });
    }
    // console.log(tasqList);
    // @ts-ignore
    return [...tasqList, ...workspaceData, ...completedList];
  }

  get teams() {
    return workflowModule.teams;
  }


  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }


  get activeCycleTimeDays() {
    return tasqsListModule.cycleTimeDays;
  }

  get activeDefermentDays() {
    return tasqsListModule.defermentDays;
  }

  get activeTeams() {
    return tasqsListModule.activeFilterTeam;
  }

  get activeRoutes() {
    return tasqsListModule.activeFilterRoute;
  }

  get activeAreas() {
    return tasqsListModule.activeFilterArea;
  }

  get kanbanTasq() {
    return tasqsListModule.kanbanSelectedTasq;
  }

  get activeCycleTimeLessThanFilter() {
    return tasqsListModule.cycleTimeLessThanFilter;
  }

  get activeCycleTimeGreaterThanFilter() {
    return tasqsListModule.cycleTimeGreaterThanFilter;
  }

  get activeUnitsLessThanFilter() {
    return tasqsListModule.unitsLessThanFilter;
  }

  get activeUnitsGreaterThanFilter() {
    return tasqsListModule.unitsGreaterThanFilter;
  }

  checkMove(event) {
    const { draggedContext } = (event);
    this.activeMoveTasqID = (draggedContext.element.id);
    // console.log(event.from.id);
    // console.log(event.to.id);
  }

  closeFilterUserMenu() {
    this.showFilterUserMenu = false;
  }

  closeFilterRouteMenu() {
    this.showFilterRouteMenu = false;
  }

  closeFilterAreasMenu() {
    this.showFilterAreasMenu = false;
  }

  closeFilterCycleTimeMenu() {
    this.showFilterCycleTimeMenu = false;
  }

  closeFilterDefermentMenu() {
    this.showFilterDefermentMenu = false;
  }

  closeFilterTeamMenu() {
    this.showFilterTeamMenu = false;
  }

  closeFilterJobTypeMenu() {
    this.showFilterJobTypeMenu = false;
  }

  isTasqFromScheduledPage(tasqID) {
    for (let x = 0; x < this.tasqs.length; x++) {
      // @ts-ignore
      if (this.tasqs[x].id == tasqID && this.tasqs[x].scheduledJobID != null) {
        return true;
      }
    }
    return false;
  }

  getTasqFromScheduledPage(tasqID) {
    for (let x = 0; x < this.tasqs.length; x++) {
      // @ts-ignore
      if (this.tasqs[x].id == tasqID && this.tasqs[x].scheduledJobID != null) {
        return this.tasqs[x];
      }
    }
    return null;
  }

  async checkAdd(event) {
    console.log(event.from.id);
    this.showWaitingOnModal = false;

    console.log(event.to.id);
    this.sleep(300).then(async () => {
      var scheduledTasq = this.getTasqFromScheduledPage(this.activeMoveTasqID);
      if (event.to.id.split('-')[1] !== event.from.id.split('-')[1]) {
        this.reasignTasq({ comment: '', person: event.to.id.split('-')[1], id: this.activeMoveTasqID });
        this.$eventBus.$emit('SHOW_ALERT', 'Successfully reassigned!');
      } else if (this.activeMoveTasqID && event.from.id.split('-')[0] === 'waitingOn') {
        await tasqActionsModule.unsnoozeJob(this.activeMoveTasqID);
        this.hideWellDetail = false;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      } else if (this.activeMoveTasqID && event.to.id.split('-')[0] === 'waitingOn' && !scheduledTasq) {
        this.hideWellDetail = true;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;

      } else if (this.activeMoveTasqID && event.to.id.split('-')[0] === 'scheduled') {
        var scheduledTasq = this.getTasqFromScheduledPage(this.activeMoveTasqID);

        if (scheduledTasq == null) {
          this.popupBackground = true;
          this.addToSchedulePopupInnerShowing = true;
        } else {
			  updateStatusVal = 'Scheduled';
		      await scheduleModule.updateItemStatus({
            // @ts-ignore
            item_id: scheduledTasq.scheduledJobID,
            item_status: updateStatusVal,
            payload: {},
          });
		  }

		  // this.showModal = true
		  this.showScheduleModal = true;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        // tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
      } else if (this.activeMoveTasqID) {
      // this.hideSignals = true;
        this.hideWellDetail = false;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      // this.$refs.childRef?.prepareAction('reassign');
      }

      if (event.from.id != event.to.id && this.isTasqFromScheduledPage(this.activeMoveTasqID)) {
        var updateStatusVal: any = null;
        if (event.to.id.split('-')[0] === 'waitingOn') {
          this.showWaitingOnModal = true;
          updateStatusVal = 'Waiting On';
        } else if (event.to.id.split('-')[0] === 'scheduled') {
          updateStatusVal = 'Scheduled';
        } else if (event.to.id.split('-')[0] === 'new') {
          updateStatusVal = 'New';
        } else if (event.to.id.split('-')[0] === 'inprogress') {
          updateStatusVal = 'In Progress';
        } else if (event.to.id.split('-')[0] === 'resolved') {
          updateStatusVal = 'Done';
        }
        var scheduledTasq = this.getTasqFromScheduledPage(this.activeMoveTasqID);

        // # New, In Progress, Waiting On, Scheduled, Done
        //   if (updateStatusVal && event.from.id.split('-')[0] != 'scheduled') {
        if (updateStatusVal !== 'Waiting On') {
          await scheduleModule.updateItemStatus({
            // @ts-ignore
            item_id: scheduledTasq.scheduledJobID,
            item_status: updateStatusVal,
            payload: {},
          });

			  if (event.to.id.split('-')[0] != 'waitingOn') {
            await this.getKanbanData({ excludeTasqLoading: false });
			  }
        } else if (scheduledTasq && scheduledTasq.scheduledJobID && this.showWaitingOnModal) {
          // console.log('prepare Action');
          this.prepareAction(scheduledTasq.scheduledJobID);
        }
      }
    });
  }

  prepareAction(scheduleJobId) {
    this.waitingOnAction = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      id: scheduleJobId,
      well: '',
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    this.waitingOnAction.type = 'wait';
    this.waitingOnAction.title = 'Please select status for Schedule Job:';
    this.waitingOnAction.confirmText = 'Update';
    this.$dialog.show('actionModal');
  }

  openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }

  validateDialog() {
    if (!this.waitingOnAction.date) {
      this.actionErrors.push('date');
    }
    if (!this.waitingOnAction.reason) {
      this.actionErrors.push('reason');
    }
  }

  async onActionDialogClosed(response) {
    // console.log(response)
    this.actionErrors = [];
    if (response.isOk) {
      this.validateDialog();
      if (this.actionErrors.length) {
        this.openDialog();
        return;
      }
      let date: any = new Date(this.waitingOnAction.date);
      date.setDate(date.getDate() + 1);
      const year = date.getFullYear();
      const month = (1 + date.getMonth()).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      date = `${month}/${day}/${year}`;
      //  const successText = 'Successfully added to waitlist!';
      await scheduleModule.updateItemStatus({
        // @ts-ignore
        item_id: this.waitingOnAction.id,
        item_status: 'Waiting On',
        payload: {
          Status: true, Comment: this.waitingOnAction.comment, WaitStartDate: new Date().toLocaleDateString(), UntilDate: date, WaitUntilReason: this.waitingOnAction.reason,
        },
      });

      this.showWaitingOnModal = false;

      await this.getKanbanData({ excludeTasqLoading: false });
      // this.$eventBus.$emit(SHOW_ALERT, successText);
    }

    this.waitingOnAction = {};
  }

  get activeTasq() {
    return tasqsListModule.activeTasq;
  }

  get activeTasqsCount() {
    return this.filteredTasqs.filter((i) => (i.stepOneCompleted && !i.completed) || (i.validationResponse && Object.keys(i.validationResponse).length > 0)).length;
  }

  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }

  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      // @ts-ignore
      initials: getNameByEmail(i.first_name.charAt(0) + i.last_name.charAt(0)),
      value: i.email,
      color: this.randomBackground(),
    })).sort((a, b) => a.text.localeCompare(b.text)) as any;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async addJob() {
    this.addToScheduleIsLoading = true;
	  // @ts-ignore
    this.addJobIsLoading = true;
    let groupSelected = null;
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
        // @ts-ignore
        groupSelected = workspaceModule.activeTable.Groups[x];
      }
    }

    const columns: any[] = [];
    for (const [key, value] of Object.entries(this.addJobResponseData)) {
      // @ts-ignore
      if (value.RequiredOnJobCreation) {
        // @ts-ignore
        if (value.Response == '' || value.Response == null || value.Response == undefined || value.Response == [] || value.Response == {}) {
          console.log('[x] Did not fill out all required fields');

          this.addToScheduleIsLoading = false;
          // @ts-ignore
          this.addJobIsLoading = false;
          return;
        }
      }
      // @ts-ignore
      if (value.ColumnType == 'STATUS') {
        // @ts-ignore
        value.Response = 'Scheduled';
      }
      // @ts-ignore
      if (value.ColumnType == 'WELL') {
        // @ts-ignore
        // @ts-ignore
        if (value.Response.value != null) {
          // @ts-ignore
          value.Response = value.Response.value;
        } else {

        }
      }
      columns.push(value);
    }

    await scheduleModule.postNewGroupItem({
      username: accountModule.user.email.toLowerCase(),
      // @ts-ignore
      workspace_id: workspaceModule.activeWorkspace.ID,
      // @ts-ignore
      table_id: workspaceModule.activeTable.ID,
      // @ts-ignore
      group_id: groupSelected.ID,
      columns: JSON.stringify(columns),
      prediction_id: this.activeMoveTasqID,
    });
    // @ts-ignore
    this.addJobIsLoading = false;
    this.addNewJobHideClicked();
    await this.getKanbanData({ excludeTasqLoading: false });
    this.addToScheduleIsLoading = false;
  }

  // NEEDED
  addNewJobHideClicked() {
    this.popupBackground = false;
    // @ts-ignore
    this.addToSchedulePopupInnerShowing = false;
  }

  addJobDidEnterChecklistItem(customName, e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const newChecklist = {
        // @ts-ignore
        value: this.checklistTemporaryText,
        checked: false,
      };
      // Do something
      this.addJobResponseData[customName].Response = [newChecklist].concat(this.addJobResponseData[customName].Response);
      // @ts-ignore
      this.checklistTemporaryText = '';
    }
  }

  addJobRemoveChecklistItem(customName, checklistIndex) {
    this.addJobResponseData[customName].Response.splice(checklistIndex, 1);
    this.refreshResponseDataKey += 1;
  }

  addJobShowChecklistPopup() {
    this.addJobChecklistPopup = true;
  }

  addJobHideChecklistPopup() {
    this.addJobChecklistPopup = false;
  }

  addJobColumns() {
    if (this.addJobGroupSelected == '') {
      return;
    }
    if (workspaceModule.activeTable == null) {
      return;
    }
    let groupSelected;
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
        // @ts-ignore
        groupSelected = workspaceModule.activeTable.Groups[x];
      }
    }

    const columns = groupSelected.Columns;
    return columns;
  }

  get scheduleNames() {
    const results: any[] = [];
    if (workspaceModule.activeTables == null) {
      return results;
    }
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTables.length; x++) {
      // @ts-ignore
      results.push({
        value: workspaceModule.activeTables[x].ID,
        text: workspaceModule.activeTables[x].TableTitle,
      });
    }
    return results;
  }

  get groupNames() {
    const results = [];
    if (workspaceModule.activeTable == null) {
      return results;
    }
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      results.push(workspaceModule.activeTable.Groups[x].GroupName);
    }
    return results;
  }

  // addToScheduleSelectedSchedule = null

  addToScheduleDidSelectSchedule() {
    // @ts-ignore
    workspaceModule.setActiveTable({ id: this.addToScheduleScheduleSelected });
  }

  addToScheduleDidSelectGroup() {
    this.addToScheduleSetupPage();
  }

  addJobRefreshTable() {
    this.refreshResponseDataKey += 1;
  }

  addToScheduleSetupPage() {
    const columns = this.addJobColumns();
    // @ts-ignore
    for (let x = 0; x < columns.length; x++) {
      // @ts-ignore
      if (columns[x].ColumnType == 'WELL') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: { text: this.activeTasq!.wellName, value: this.activeTasq!.wellName },
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'USER') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: this.activeTasq!.username,
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'VENDOR') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: {
            PhoneNumber: '',
            Email: '',
            FullName: '',
          },
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'CHECKLIST') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'FILE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: null,
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'TESTERS') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'TASQ_DATE_RANGE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'COST') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'TAGS') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'TEXT') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'DATE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'START_DATE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'DUE_DATE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'PROCEDURE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: null,
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'EMAIL') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
        // @ts-ignore
      } else if (columns[x].ColumnType == 'PHONE_NUMBER') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      } else {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      }
    }
    this.refreshResponseDataKey += 1;
  }

  closeDialog() {
	  this.showScheduleModal = false;
    // console.log('hideing')
    this.showModal = false;
    this.popupBackground = false;
    this.showWaitingOnModal = false;
    this.$dialog.hide('actionModal');
    this.waitingOnAction = {};
    this.sleep(2000).then(() => {
    //  console.error('hideing')
      this.setDraggableData();
      this.$dialog.hide('actionModal');
    });
  }

  setCycleTimeMaxValue(search) {
    if (search) {
      this.maxNumCycleMax = 1;
      this.cycleTimeValues = [search];
    } else {
      this.maxNumCycleMax = 7;
      this.cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70', '80', '90'];
    }
  }

  addToScheduleHideClicked() {
    this.closeDialog();
    this.popupBackground = false;
    // @ts-ignore
    this.addToSchedulePopupInnerShowing = false;
  }

  setDefermentMaxValue(search) {
    if (search) {
      this.maxNumDefermentMax = 1;
      this.defermentUnitValues = [search];
    } else {
      this.maxNumDefermentMax = 7;
      this.defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];
    }
  }

  get jobTypeFilter() {
    return tasqsListModule.jobTypeFilter;
  }

  randomBackground() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  get completedTasqCount() {
    return this.filteredTasqs.filter((i) => i.completed).length;
  }

  get notStartedCount() {
    return this.filteredTasqs.length - this.activeTasqsCount - this.completedTasqCount;
  }

  get tasqsJobTypes() {
    return [...new Set(this.filteredTasqs.map((i) => i.overriddenName))];
  }

  get usernames() {
    return tasqsListModule.usernamesList;
  }

  get tasqsJobTypesData() {
    const job_type_dict = {};
    for (var x = 0; x < this.tasqsJobTypes.length; x++) {
      job_type_dict[this.tasqsJobTypes[x]] = 0;
    }
    for (var x = 0; x < this.tasqs.length; x++) {
      if (job_type_dict[this.tasqs[x].overriddenName] != null) {
        job_type_dict[this.tasqs[x].overriddenName] += 1;
      }
      if (this.tasqs[x].overriddenName != 'Scheduled Job' && this.tasqs[x].scheduledJobStatus == 'Scheduled') {
        job_type_dict['Scheduled Job'] += 1;
      }
      // if (this.tasqs[x].overriddenName )
    }

    const return_array: any[] = [];
    for (var x = 0; x < this.tasqsJobTypes.length; x++) {
      return_array.push(job_type_dict[this.tasqsJobTypes[x]]);
      // job_type_dict[] = 0
    }

    return return_array;
    // return this.tasqsJobTypes.map(
    //   (i) => this.tasqs.filter((t) => t.overriddenName === i).length,
    // );
  }

  isScheduledTasq(tasq) {
    const scheduledJobStatus = !!(tasq.scheduledJobStatus !== null && typeof tasq.scheduledJobStatus === 'object' && tasq.scheduledJobStatus.Status.toLowerCase() == 'scheduled');
    return (tasq.scheduledJobID != null && (tasq.scheduledJobStatus == 'Scheduled' || tasq.scheduledJobStatus == '' || tasq.scheduledJobStatus == null || tasq.scheduledJobStatus == undefined || scheduledJobStatus));
  }

  isWaitingOnTasq(tasq) {
    // console.log(tasq.scheduledJobStatus)
    if (tasq.scheduledJobStatus && tasq.scheduledJobStatus == 'Waiting On') {
      return true;
    }

    for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
      // @ts-ignore
      if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {
        return false;
      }
      if (tasqsListModule.waitingTasqList[x].id == tasq.id) {
        return true;
      }
    }

    return false;
  }

  scheduledJobIsDone(tasq) {
    if (tasq.scheduledJobStatus != null && tasq.scheduledJobStatus == 'Done') {
      return true;
    }
    return false;
  }

  selectUsername(users) {
    // this.dataLoading = true;
    tasqsListModule.setUsernamesBulkList(users);
    // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    localStorage.setItem('kanbanUsernamesFilter', JSON.stringify(tasqsListModule.usernamesList));
    // this.dataLoading = false;
    // this.setDraggableData();
  }

  getAcceptedSetpointTasqs(username) {
    const results: any[] = [];
    for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
      if (tasqsListModule.waitingTasqList[x].username.toLowerCase() != username.toLowerCase()) {
        continue;
      }
      // @ts-ignore
      if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted') && !tasqsListModule.waitingTasqList[x].completed) {
        results.push(tasqsListModule.waitingTasqList[x]);
      }
    }
    return results;
  }

  getTasqsInProgress(tasqs) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];
      if ((Object.keys(tasq.validationResponse).length > 0 || tasq.lastResponseTime.length || (tasq.scheduledJobStatus != null && tasq.scheduledJobStatus == 'In Progress')) && !tasq.completed && !this.scheduledJobIsDone(tasq) && !tasq.completedForToday && !this.isWaitingOnTasq(tasq)) {
        results.push(tasq);
      }
    }
    return results;
  }

  getWaitinOnTasqList(tasqs, username) {
    const results: any[] = [];

    for (let x = 0; x < tasqs.length; x++) {
      if (this.isWaitingOnTasq(tasqs[x])) {
        results.push(tasqs[x]);
      }
    }
    for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
      if (tasqsListModule.waitingTasqList[x].username.toLowerCase() == username.toLowerCase()) {
        // @ts-ignore
        if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {
          return false;
        }
        if (!tasqsListModule.waitingTasqList[x].completed) {
          let is_in = false;
          for (let y = 0; y < results.length; y++) {
            if (results[y].id == tasqsListModule.waitingTasqList[x].id) {
              is_in = true;
            }
          }
          if (!is_in) {
            results.push(tasqsListModule.waitingTasqList[x]);
          }
        }
      }
    }

    //   for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
    //       if (tasqsListModule.waitingTasqList[x].username.toLowerCase() == username.toLowerCase()) {
    //             // @ts-ignore
    //             if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {
    //             return false;
    //             }
    //             if (!tasqsListModule.waitingTasqList[x].completed) {
    //             results.push(tasqsListModule.waitingTasqList[x]);
    //             }
    //       }
    //   }

    //   for (var x = 0; x < this.tasqs.length; x++) {

    //       if (this.tasqs[x].username.toLowerCase() == username.toLowerCase()) {
    //         // @ts-ignore
    //         if (this.tasqs[x].scheduledJobID != null && this.tasqs[x].scheduledJobStatus == 'Waiting On') {
    //             results.push(this.tasqs[x])
    //         }
    //       }

    //   }

    return results;
  }

  getScheduledTasqs(tasqs, email) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];

      if (this.isScheduledTasq(tasq) && !tasq.completed && !this.scheduledJobIsDone(tasq) && !this.isWaitingOnTasq(tasq)) {
		  if (this.jobTypeFilter.length == 0 || this.jobTypeFilter.includes('Scheduled job')) {
			  results.push(tasq);
		  }
      }
    }

    return results;
  }

  getNewTasqs(tasqs) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];
      //  console.log((tasq.validationResponse));
      //  console.log(tasq.wellName);
      //  console.log(Object.keys(tasq.validationResponse).length > 0 );
      if (Object.keys(tasq.validationResponse).length < 1 && !tasq.completed && !this.scheduledJobIsDone(tasq) && (!this.isScheduledTasq(tasq) || (tasq.scheduledJobStatus != null && tasq.scheduledJobStatus == 'New')) && !this.isWaitingOnTasq(tasq)) {
        results.push(tasq);
      }
    }
    return results;
  }

  getResolvedTasqs(tasqs, username) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];

      if (tasq.completed || tasq.completedForToday || this.scheduledJobIsDone(tasq)) {
        results.push(tasq);
      }
    }
    const setpointResults = this.getAcceptedSetpointTasqs(username.toLowerCase());
    results.push(...setpointResults);
    return results;
  }

  closeFilterDialog() {
    this.showFilterMenu = false;
  }

  async applyFiltering() {
    this.dataLoading = true;
    this.showFilterMenu = false;
    this.showFilterCycleTimeMenu = false;
    this.showFilterDefermentMenu = false;
    this.showFilterJobTypeMenu = false;
    this.showFilterUserMenu = false;
    this.showFilterTeamMenu = false;
    this.dataLoading = true;
    tasqsListModule.setCycleTimeLessThanFilter(this.cycleTimeLessThanFilter);
    tasqsListModule.setUnitsLessThanFilter(this.unitsLessThanFilter);
    tasqsListModule.setUnitsGreaterThanFilter(this.unitsGreaterThanFilter);
    tasqsListModule.setCycleTimeGreaterThanFilter(this.cycleTimeGreaterThanFilter);
    tasqsListModule.setActiveAreaFilter(this.selectedAreas);
    tasqsListModule.setActiveTeamFilter(this.selectedTeams);
    tasqsListModule.setActiveRouteFilter(this.selectedRoutes);
    tasqsListModule.setJobTypeFilter(this.typesOptions);

    // this.selectUsername();
    // tasqsListModule.setUsernamesBulkList(this.selectedUsernames.map((u) => u.value));
    // tasqsListModule.setUsernamesBulkList(this.selectedUsernames.map((u) => u.value));
    // localStorage.setItem('kanbanCycleFilter', this.cycleTimeDays);
    // localStorage.setItem('kanbanDefermentFilter', this.defermentDays);
    // localStorage.setItem('kanbanJobTypeFilter', JSON.stringify(this.typesOptions));

    // if (this.filterType === 'users') {
    //   console.log((this.draggableData));
    //   return;
    // }

    // console.log(this.selectedUsernames.map((u) => u.value))
    // if (this.filterType !== 'users') {
    //   await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    // }
    this.dataLoading = false;
    this.setDraggableData();
    this.filterType = '';
  }

  async resetFiltering() {
    this.dataLoading = true;
    if (this.filterType === 'users') {
      this.dataLoading = false;
      this.selectedUsernames = [];
      await this.applyFiltering();
      // this.selectUsername([]);
    } else if (this.filterType === 'jobType') {
      this.typesOptions = [];
      await this.applyFiltering();
    } else if (this.filterType === 'cycleTime') {
      this.cycleTimeGreaterThanFilter = null;
      this.cycleTimeLessThanFilter = null;
      await this.applyFiltering();
    } else if (this.filterType === 'deferment') {
      this.unitsGreaterThanFilter = null;
      this.unitsLessThanFilter = null;
      await this.applyFiltering();
    } else if (this.filterType === 'routes') {
      this.selectedRoutes = [];
      await this.applyFiltering();
    } else if (this.filterType === 'teams') {
      this.selectedTeams = [];
      await this.applyFiltering();
    } else if (this.filterType == 'areas') {
      this.selectedAreas = [];
      await this.applyFiltering();
    }
    // this.dataLoading = fa;
  }

  reasignTasq(action: any) {
    tasqActionsModule.mutateTasqReassign({ comment: '', person: action.person, id: action.id });
  }

  get totalScheduledItems() {
    // @ts-ignore
    return workspaceModule.totalItems;
  }

  setDraggableData() {
    const users = this.selectedUsernames.map((u) => u.value);
    // @ts-ignore
    const data: any = Object.entries(groupBy(this.filteredTasqs, 'username')).map((i: any) => ({
      username: getNameByEmail(i[0].toLowerCase()) || 'Unassign',
      showUserDetails: false,
      color: this.randomBackground(),
      cardLimitNew: 10,
      cardLimitInProgress: 10,
      cardLimitWaitingOn: 10,
      // ts-ignore
      initials: this.availableUsers.find((u) => u && u.value === i[0]) ? this.availableUsers.find((u) => u && u.value === i[0]).initials : `${i[0].charAt(0)}${i[0].charAt(1)}`,
      email: i[0],
      tasqs: i[1] as TasqJob[],
      new: this.getNewTasqs(i[1] as TasqJob[]),
      inProgress: this.getTasqsInProgress(i[1] as TasqJob[]), // i[1].filter(i => i.lastResponseTime.length && !i.completed),
      waitingOn: this.getWaitinOnTasqList(i[1] as TasqJob[], i[0]),
      scheduled: this.getScheduledTasqs(i[1] as TasqJob[], i[0]),
      resolved: this.getResolvedTasqs(i[1] as TasqJob[], i[0]),
    })).sort((a, b) => a.username.localeCompare(b.username));

    if (users && users.length) {
      this.draggableData = data.filter((group) => users.includes(group.email));
    } else {
      this.draggableData = data;
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get enableRoutes() {
    return scheduleModule.enabledRoutes.map((r) => r.Route).sort();
  }

  get areas() {
    return scheduleModule.areas;
  }

  async created() {
    tasqsListModule.setActivePage('Kanban');
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
    await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase(), useExisting: true });
    this.selectedRoutes = [];
    this.selectedTeams = [];
    this.cycleTimeGreaterThanFilter = null;
    this.cycleTimeLessThanFilter = null;
    this.unitsGreaterThanFilter = null;
    this.unitsLessThanFilter = null;
    this.dataLoading = true;
    tasqsListModule.setUsernamesList(null);
    tasqsListModule.setUsernamesList(null);
    tasqsListModule.setUsernamesBulkList([]);
    // console.log(workflowModule.user)
    this.selectedUsernames = [];

    if (workflowModule.user.defaultUserFilter === 'All') {
      // No filters applied
      // this.selectedAreas = workflowModule.user.areas;
    } else if (workflowModule.user.defaultUserFilter === 'My Routes' && workflowModule.user.routes) {
      this.selectedRoutes = JSON.parse(workflowModule.user.routes);
    } else if (workflowModule.user.defaultUserFilter === 'Myself') {
      // tasqsListModule.setUsernamesList(accountModule.user.email);
      // this.selectedUsernames = [(this.availableUsers.find((u) => u.value === accountModule.user
      //   .email))];
    } else if (workflowModule.user.role === 'My Area' && workflowModule.user.areas) {
      this.selectedAreas = workflowModule.user.areas;
    } else if ((workflowModule.user.role === 'Operators' || workflowModule.user.role === 'FieldOperator') && workflowModule.user.routes) {
      this.selectedRoutes = JSON.parse(workflowModule.user.routes);
    } else if (!(workflowModule.user.role === 'Operators' || workflowModule.user.role === 'FieldOperator') && workflowModule.user.areas) {
      // console.log('My Areas');
      this.selectedAreas = workflowModule.user.areas;
    }

    const promises: any = [];

    promises.push(tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM));

    promises.push(workspaceModule.getWorkspaces({}));
    // @ts-ignore

    Promise.all(promises).then(() => {
      workspaceModule.setTotalItems();
      this.setDraggableData();
      this.dataLoading = false;
    }, (err) => {
      console.log('Error:');
      console.log(err);
    });
    // this.getKanbanData({ excludeTasqLoading: true });

    const promiseList: any = [];
    promiseList.push(scheduleModule.getEnabledRoutes());
    promiseList.push(workflowModule.listTeams({ useExisting: true }));
    promiseList.push(scheduleModule.listAreas({ useExisting: true }));
    promiseList.push(assetsModule.getEnabledWells());
    promiseList.push(workspaceModule.getWorkspaces(null));
    promiseList.push(accountModule.getReassignmentList({ useExisting: true }));
    Promise.all(promiseList).then(() => {
      console.log('resolved');
    }, (err) => {
      console.log('Error:');
      console.log(err);
    });
  }

  getKanbanData({ excludeTasqLoading = false }) {
    // this.dataLoading = true;
    this.hideWellDetail = false;
    this.hideSignals = false;
    this.showModal = false;
    this.showScheduleModal = false;
    //  this.dataLoading = true;
    // await tasqsListModule.getWaitList();
    // const kanbanCycleFilter = localStorage.getItem('kanbanCycleFilter');
    // const kanbanDefermentFilter = localStorage.getItem('kanbanDefermentFilter');
    // const kanbanJobTypeFilter = localStorage.getItem('kanbanJobTypeFilter');
    // let kanbanUsernamesFilter = localStorage.getItem('kanbanUsernamesFilter');
    // if (kanbanCycleFilter) {
    //   tasqsListModule.setCycleTimeDaysFilter(kanbanCycleFilter);
    //   this.cycleTimeDays = tasqsListModule.cycleTimeDays;
    // }
    // if (kanbanDefermentFilter) {
    //   tasqsListModule.setDefermentDaysFilter(kanbanDefermentFilter);
    //   this.defermentDays = tasqsListModule.defermentDays;
    // }
    // if (kanbanJobTypeFilter) {
    //   tasqsListModule.setJobTypeFilter(JSON.parse(kanbanJobTypeFilter));
    //   this.typesOptions = tasqsListModule.jobTypeFilter;
    // } else {
    //   this.typesOptions = [];
    //   tasqsListModule.setJobTypeFilter([]);
    // }
    // if (kanbanUsernamesFilter) {
    //   this.selectedUsernames = [];
    //   kanbanUsernamesFilter = JSON.parse(kanbanUsernamesFilter);
    //   this.availableUsers.forEach((i) => {
    //     if (kanbanUsernamesFilter?.includes(i.value)) {
    //       this.selectedUsernames.push(i);
    //     }
    //   });
    //   tasqsListModule.setUsernamesBulkList(kanbanUsernamesFilter);
    // }

    if (!excludeTasqLoading) {
      const promises: any = [];
      this.dataLoading = true;

      promises.push(workspaceModule.getWorkspaces({}));
      promises.push(tasqsListModule.getWaitList());
      // @ts-ignore

      Promise.all(promises).then(() => {
        workspaceModule.setTotalItems();
        this.setDraggableData();
        this.dataLoading = false;
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    }

    // @ts-ignore
  }

  beforeDestroy() {
    tasqsListModule.setActivePage('');
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
  }

  setActionPerform() {
    this.isActionNotPerform = true;
  }

  @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    if (id) {
      this.showModal = true;
    } else {
      this.showScheduleModal = false;
      this.$dialog.hide('actionModal');
      this.showModal = false;

      // this.setLoffding()
    }
  }

   @Watch('showScheduleModal')
  async onShowScheduleModalChange(value) {
    if (value) {
      this.showModal = false;
    }
  }

@Debounce(50)
   setLoading() {
     if (!this.isActionNotPerform) {
       //  this.dataLoading = true;
     }
     this.sleep(1500).then(() => {
       this.dataLoading = false;
     });
   }

   @Watch('activeTasq')
async onactiveTasqChange(id: string) {
  if (id) {
    this.showModal = true;
    this.isActionNotPerform = false;
  } else if (!this.isActionNotPerform) {
    this.sleep(1500).then(async () => {
      this.showScheduleModal = false;
      this.showModal = false;
      // this.dataLoading = true;
      //
      // this.setDraggableData();
    });
  } else {
    await this.getKanbanData({ excludeTasqLoading: false });
    this.setDraggableData();
  }
}
}
